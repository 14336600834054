import * as React from "react";
import constants from "constants/index";
import { navigate } from "gatsby";

export default () => {
  React.useEffect(() => {
    navigate(`https://${constants.APP_DOMAIN}/auth/sign-up`);
  }, []);

  return null;
};
